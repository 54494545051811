const state = {
    isAudience: false,
    isSocialNetwork: false,
};

const getters = {
    getIsAudience: (state) => state.isAudience,
    getIsSocialNetwork: (state) => state.isSocialNetwork,
};

const actions = {
    setIsAudience({ commit }, value) {
            commit('SET_IS_AUDIENCE', value);
    },
    setIsSocialNetwork({ commit }, value) {
            commit('SET_IS_SOCIAL_NETWORK', value);
    }
}

const mutations = {
    SET_IS_AUDIENCE(state, isAudience) {
        state.isAudience = isAudience;
    },
     SET_IS_SOCIAL_NETWORK(state, isSocialNetwork) {
        state.isSocialNetwork = isSocialNetwork;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};