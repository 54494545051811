import {sortTags} from "../../utils";

const state = {
    isSocialNetworkSidenavVisible: false,
    socialDefaultTags: [
        {label: "Tous les ministères", role: "allMinistries"},
        {label: "Mois glissant", role: "currentDate"}
    ],
    socialTags: [],
    socialNetworkParams: {},
    socialNetworkMinistries: [],
    socialNetworkOrganisations: [],
    socialNetworkCompany: [],
    socialNetworkSelectedPeriod: "",
    socialNetworkPeriod: "31",
    socialNetworkStartDatePicker: undefined,
    socialNetworkEndDatePicker: undefined,
    selectedSocialNetworkMinistries: undefined,
    selectedSocialNetworkOrganisations: undefined,
    selectedSocialNetworkCompany: undefined,
};

const getters = {
    getIsSocialNetworkSidenavVisible: (state) => state.isSocialNetworkSidenavVisible,
    getSocialDefaultTags: (state) => state.socialDefaultTags,
    getSocialTags: (state) => state.socialTags,
    getSocialNetworkParams: state => state.socialNetworkParams,
    getSocialNetworkMinistries: state => state.socialNetworkMinistries,
    getSocialNetworkOrganisations: state => state.socialNetworkOrganisations,
    getSocialNetworkCompany: state => state.socialNetworkCompany,
    getSocialNetworkSelectedPeriod: state => state.socialNetworkSelectedPeriod,
    getSocialNetworkPeriod: state => state.socialNetworkPeriod,
    getSocialNetworkStartDatePicker: state => state.socialNetworkStartDatePicker,
    getSocialNetworkEndDatePicker: state => state.socialNetworkEndDatePicker,
    getSelectedSocialNetworkMinistries: state => state.selectedSocialNetworkMinistries,
    getSelectedSocialNetworkOrganisations: state => state.selectedSocialNetworkOrganisations,
    getSelectedSocialNetworkCompany: state => state.selectedSocialNetworkCompany,

};

const actions = {
    setIsSocialNetworkSidenavVisible({commit}, isSocialNetworkSidenavVisible) {
        commit('SET_IS_SOCIAL_NETWORK_SIDENAV_VISIBLE', isSocialNetworkSidenavVisible);
    },
    removeSocialDefaultTag({commit}, tag) {
        commit('REMOVE_SOCIAL_DEFAULT_TAG', tag);
    },
    removeSocialTag({commit}, tag) {
        commit('REMOVE_SOCIAL_TAG', tag);
    },
    addSocialDefaultTag({commit}, tag) {
        commit('ADD_SOCIAL_DEFAULT_TAG', tag);
    },
    addSocialTag({commit}, tag) {
        commit('ADD_SOCIAL_TAG', tag);
    },
    setSocialNetworkParams({commit}, socialNetworkParams) {
        commit('SET_SOCIAL_NETWORK_PARAMS', socialNetworkParams);
    },
    setSocialNetworkMinistries({commit}, socialNetworkMinistries) {
        commit('SET_SOCIAL_NETWORK_MINISTRIES', socialNetworkMinistries);
    },
    setSocialNetworkOrganisations({commit}, socialNetworkOrganisations) {
        commit('SET_SOCIAL_NETWORK_ORGANISATIONS', socialNetworkOrganisations);
    },
    setSocialNetworkCompany({commit}, socialNetworkCompany) {
        commit('SET_SOCIAL_NETWORK_COMPANY', socialNetworkCompany);
    },
    setSocialNetworkSelectedPeriod({commit}, socialNetworkSelectedPeriod) {
        commit('SET_SOCIAL_NETWORK_SELECTED_PERIOD', socialNetworkSelectedPeriod);
    },
    setSocialNetworkPeriod({commit}, socialNetworkPeriod) {
        commit('SET_SOCIAL_NETWORK_PERIOD', socialNetworkPeriod);
    },
    setSocialNetworkStartDatePicker({commit}, socialNetworkStartDatePicker) {
        commit('SET_SOCIAL_NETWORK_START_DATE_PICKER', socialNetworkStartDatePicker);
    },
    setSocialNetworkEndDatePicker({commit}, socialNetworkEndDatePicker) {
        commit('SET_SOCIAL_NETWORK_END_DATE_PICKER', socialNetworkEndDatePicker);
    },
    setSelectedSocialNetworkMinistries({commit}, selectedSocialNetworkMinistries) {
        commit('SET_SELECTED_SOCIAL_NETWORK_MINISTRIES', selectedSocialNetworkMinistries);
    },
    setSelectedSocialNetworkOrganisations({commit}, selectedSocialNetworkOrganisations) {
        commit('SET_SELECTED_SOCIAL_NETWORK_ORGANISATIONS', selectedSocialNetworkOrganisations);
    },
    setSelectedSocialNetworkCompany({commit}, selectedSocialNetworkCompany) {
        commit('SET_SELECTED_SOCIAL_NETWORK_COMPAGNY', selectedSocialNetworkCompany);
    },
    setSocialDefaultTags({commit}, socialDefaultTags) {
        commit('SET_SOCIAL_DEFAULT_TAGS', socialDefaultTags);
    },
    setSocialTags({commit}, socialTags) {
        commit('SET_SOCIAL_TAGS', socialTags);
    },
    resetSocialDefaultTags({commit}, socialDefaultTags) {
        commit('RESET_SOCIAL_DEFAULT_TAGS', socialDefaultTags);
    },
    resetSocialTags({commit}, socialTags) {
        commit('RESET_SOCIAL_TAGS', socialTags);
    },
    resetSelectedSocialNetworkMinistries({ commit }) {
        commit('RESET_SELECTED_SOCIAL_NETWORK_MINISTRIES');
    },  
    resetSelectedSocialNetworkOrganisations({ commit }) {
        commit('RESET_SELECTED_SOCIAL_NETWORK_ORGANISATIONS');
    },
    resetSelectedSocialNetworkCompany({ commit }) {
        commit('RESET_SELECTED_SOCIAL_NETWORK_COMPAGNY');
    },
    removeSocialTagsSites({commit}) {
        commit('REMOVE_TAGS_SITES');
        commit('RESET_SELECTED_SOCIAL_NETWORK_COMPAGNY');
        commit('RESET_SELECTED_SOCIAL_NETWORK_MINISTRIES');
        commit('RESET_SELECTED_SOCIAL_NETWORK_ORGANISATIONS');
        commit('RESET_SOCIAL_NETWORK_MINISTRIES');
        commit('RESET_SOCIAL_NETWORK_ORGANISATIONS');
        commit('RESET_SITES');
    },
};


const mutations = {
    SET_IS_SOCIAL_NETWORK_SIDENAV_VISIBLE(state, isSocialNetworkSidenavVisible) {
        state.isSocialNetworkSidenavVisible = isSocialNetworkSidenavVisible;
    },
    REMOVE_SOCIAL_DEFAULT_TAG(state, socialDefaultTag) {
        state.socialDefaultTags = state.socialDefaultTags.filter(existingTag => existingTag.role !== socialDefaultTag.role);
    },
    REMOVE_SOCIAL_TAG(state, socialTag) {
        state.socialTags = state.socialTags.filter(existingTag => existingTag.role !== socialTag.role);
    },
    ADD_SOCIAL_DEFAULT_TAG(state, socialDefaultTag) {
        state.socialDefaultTags = state.socialDefaultTags.filter(existingTag => existingTag.role !== socialDefaultTag.role);
        state.socialDefaultTags.push(socialDefaultTag);
    },
    ADD_SOCIAL_TAG(state, socialTag) {
        state.socialTags = state.socialTags.filter(existingTag => existingTag.role !== socialTag.role);
        state.socialTags.push(socialTag);
        sortTags(state.socialTags);
    },
    SET_SOCIAL_NETWORK_PARAMS(state, socialNetworkParams) {
        state.socialNetworkParams = socialNetworkParams;
    },
    SET_SOCIAL_NETWORK_MINISTRIES(state, socialNetworkMinistries) {
        state.socialNetworkMinistries = socialNetworkMinistries;
    },
    SET_SOCIAL_NETWORK_ORGANISATIONS(state, socialNetworkOrganisations) {
        state.socialNetworkOrganisations = socialNetworkOrganisations;
    },
    SET_SOCIAL_NETWORK_COMPANY(state, socialNetworkCompany) {
        state.socialNetworkCompany = socialNetworkCompany;
    },
    SET_SOCIAL_NETWORK_SELECTED_PERIOD(state, socialNetworkSelectedPeriod) {
        state.socialNetworkSelectedPeriod = socialNetworkSelectedPeriod;
    },
    SET_SOCIAL_NETWORK_PERIOD(state, socialNetworkPeriod) {
        state.socialNetworkPeriod = socialNetworkPeriod;
    },
    SET_SOCIAL_NETWORK_START_DATE_PICKER(state, socialNetworkStartDatePicker) {
        state.socialNetworkStartDatePicker = socialNetworkStartDatePicker;
    },
    SET_SOCIAL_NETWORK_END_DATE_PICKER(state, socialNetworkEndDatePicker) {
        state.socialNetworkEndDatePicker = socialNetworkEndDatePicker;
    },
    SET_SOCIAL_NETWORK_MINISTRIES(state, socialNetworkMinistries) {
        state.socialNetworkMinistries = socialNetworkMinistries;
    },
    SET_SOCIAL_NETWORK_ORGANISATIONS(state, socialNetworkOrganisations) {
        state.socialNetworkOrganisations = socialNetworkOrganisations;
    },
    SET_SOCIAL_NETWORK_COMPANY(state, socialNetworkCompany) {
        state.socialNetworkCompany = socialNetworkCompany;
    },
    RESET_SOCIAL_NETWORK_MINISTRIES(state) {
        state.socialNetworkMinistries = [];
    },
    RESET_SOCIAL_NETWORK_ORGANISATIONS(state) {
        state.socialNetworkOrganisations = [];
    },
    RESET_SOCIAL_NETWORK_COMPAGNY(state) {
        state.socialNetworkCompany = [];
    },
    SET_SELECTED_SOCIAL_NETWORK_MINISTRIES(state, selectedSocialNetworkMinistries) {
        state.selectedSocialNetworkMinistries = selectedSocialNetworkMinistries;
    },
    SET_SELECTED_SOCIAL_NETWORK_ORGANISATIONS(state, selectedSocialNetworkOrganisations) {
        state.selectedSocialNetworkOrganisations = selectedSocialNetworkOrganisations;
    },
    SET_SELECTED_SOCIAL_NETWORK_COMPAGNY(state, selectedSocialNetworkCompany) {
        state.selectedSocialNetworkCompany = selectedSocialNetworkCompany;
    },
    SET_SOCIAL_DEFAULT_TAGS(state, socialDefaultTags) {
        state.socialDefaultTags = socialDefaultTags
    },
    SET_SOCIAL_TAGS(state, socialTags) {
        state.socialTags = socialTags
    },
    RESET_SOCIAL_DEFAULT_TAGS(state, resetSocialDefaultTags) {
        state.resetSocialDefaultTags = resetSocialDefaultTags
    },
    RESET_SOCIAL_TAGS(state, resetSocialTags) {
        state.resetSocialTags = resetSocialTags
    },
    RESET_SELECTED_SOCIAL_NETWORK_MINISTRIES(state, resetSelectedSocialNetworkMinistries) {
        state.selectedSocialNetworkMinistries = resetSelectedSocialNetworkMinistries; // Remettez à `undefined` ou une valeur par défaut
    },
    RESET_SELECTED_SOCIAL_NETWORK_ORGANISATIONS(state, resetSelectedSocialNetworkOrganisations) {
        state.selectedSocialNetworkOrganisations = resetSelectedSocialNetworkOrganisations; // Remettez à `undefined` ou une valeur par défaut
    },
    RESET_SELECTED_SOCIAL_NETWORK_COMPAGNY(state, resetSelectedSocialNetworkCompany) {
        state.selectedSocialNetworkCompany = resetSelectedSocialNetworkCompany;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};