<template>
  <!-- Liens directs uniquement -->
  <nav class="fr-nav" id="header-navigation" role="navigation" aria-label="Menu principal">
    <ul class="fr-nav__list">
      <li class="fr-nav__item">
        <router-link id="fr-nav-accueil" :to="{ name: 'accueil' }" class="fr-nav__link" target="_self"
          title="Accueil">Accueil</router-link>
      </li>
      <li class="fr-nav__item">
        <button class="fr-nav__btn" aria-expanded="false" aria-controls="menu-776">Audience</button>
        <div class="fr-collapse fr-menu" id="menu-776">
          <ul class="fr-menu__list">
            <li class="fr-nav__item" aria-current="page">
              <router-link id="fr-nav-visites" :to="{ name: 'visites' }" class="fr-nav__link" target="_self"
                title="Sites internet de l'État">Sites internet de l'État</router-link>
            </li>
            <li class="fr-nav__item" aria-current="page">
              <router-link id="fr-nav-visites" :to="{ name: 'reseaux-sociaux' }" class="fr-nav__link" target="_self"
                title="Réseaux sociaux de l'État">Réseaux sociaux de l'État</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="fr-nav__item">
        <router-link id="fr-nav-sites" :to="{ name: 'sites' }" class="fr-nav__link" target="_self"
          title="Panorama">Panorama</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'navigationDsfr'
}
</script>
