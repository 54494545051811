<template>
    <div class="fr-container--fluid fr-p-2w socialNetworkSidenav" role="navigation" aria-label="Menu latéral">
        <div class="fr-grid-row">
            <div class="fr-col-12">
                <div class="socialNetworkSidenav__container fr-p-0">
                    <h2 class="fr-h6 fr-pb-1v fr-pt-4w" id="fr-sidemenu-title">
                        Affiner la recherche
                    </h2>
                    <button id="fr-btn-close-social-network"
                        class="socialNetworkSidenav__container--close-btn fr-my-2w">
                        <span class="fr-icon-close-line" @click="closeSocialNetworkSidenav" aria-hidden="true"
                            title="Fermer"></span>
                    </button>
                </div>

                <div class="socialNetworkSidenav__title-line fr-p-0"></div>

                <div class="fr-select-group">
                    <fieldset class="socialNetworkSidenav__fieldset fr-px-0 fr-mx-0">
                        <label class="fr-label" for="select-minister">
                            Ministère de tutelle
                        </label>
                        <select v-model="selectedSocialNetworkMinistries" class="fr-select"
                            id="select-minister-social-network-page" name="select" title="Sélectionner un ministère"
                            @change="changeMinistries">
                            <option value="undefined" selected disabled hidden>Sélectionner un ministère</option>
                            <option v-for="ministery in ministries" :value="ministery['value']"
                                :key="ministery['value']">
                                {{ ministery["label"] }}
                            </option>
                        </select>
                    </fieldset>
                </div>

                <div class="fr-select-group">
                    <fieldset class="socialNetworkSidenav__fieldset fr-px-0 fr-mx-0">
                        <label class="fr-label" for="select-entity">
                            Entité/ Organisation
                        </label>
                        <select v-model="selectedSocialNetworkOrganisations" class="fr-select"
                            id="select-organisation-social-network-page" name="select"
                            title="Sélectionner un site une entité/organisation" @change="changeOrganisations">
                            <option value="undefined" selected disabled hidden>Sélectionner un site une
                                entité/organisation</option>
                            <option v-for="organisation in organisations" :value="organisation['value']"
                                :key="organisation['value']">
                                {{ organisation["label"] }}
                            </option>
                        </select>
                    </fieldset>
                </div>

                <div class="fr-select-group">
                    <fieldset class="socialNetworkSidenav__fieldset fr-px-0 fr-mx-0">
                        <label class="fr-label" for="select-social-network">
                            Réseau social
                        </label>
                        <select v-model="selectedSocialNetworkCompany" class="fr-select"
                            id="select-social-network-company" name="select" title="Sélectionner un réseau social"
                            @change="changeSocialCompagny">
                            <option value="undefined" selected disabled hidden>Sélectionner un réseau social</option>
                            <option v-for="socialNetwork in socialNetworkCompanies" :value="socialNetwork['value']"
                                :key="socialNetwork['value']">
                                {{ socialNetwork["label"] }}
                            </option>
                        </select>
                    </fieldset>
                </div>

                <button class="fr-btn fr-btn--tertiary fr-mt-1w fr-mb-3w fr-btn--icon-right fr-icon-close-circle-line"
                    @click="resetSites" title="Réinitialiser" id="fr-btn-tertiary-reset-social-network">
                    Réinitialiser
                </button>

                <div class="socialNetworkSidenav__line fr-p-0"></div>

                <fieldset class="fr-fieldset" id="radio-hint"
                    aria-labelledby="radio-hint-social-network-legend radio-hint-social-network-current-week radio-hint-social-network-current-month radio-hint-social-network-current-year fr-btn-tertiary-social-networks-reset">
                    <legend class="fr-fieldset__legend--bold fr-fieldset__legend" id="radio-hint-social-network-legend">
                        Période prédéfinie
                    </legend>
                    <div class="fr-fieldset__element">
                        <div class="fr-radio-group">
                            <input v-model="selectedPeriod" type="radio" id="radio-hint-social-network-current-week"
                                name="radio-hint" value="current-week" @change="changeCurrentDate" />
                            <label class="fr-label" for="radio-hint-social-network-current-week"
                                title="Semaine courante">Semaine
                                courante</label>
                        </div>
                    </div>
                    <div class="fr-fieldset__element">
                        <div class="fr-radio-group">
                            <input v-model="selectedPeriod" type="radio" id="radio-hint-social-network-current-month"
                                name="radio-hint" value="current-month" @change="changeCurrentDate" />
                            <label class="fr-label" for="radio-hint-social-network-current-month" title="Mois courant">
                                Mois courant</label>
                        </div>
                    </div>
                    <div class="fr-fieldset__element">
                        <div class="fr-radio-group">
                            <input v-model="selectedPeriod" type="radio" id="radio-hint-social-network-current-year"
                                name="radio-hint" value="current-year" @change="changeCurrentDate" />
                            <label class="fr-label" for="radio-hint-social-network-current-year" title="Année courante">
                                Année courante</label>
                        </div>
                    </div>
                </fieldset>

                <div class="socialNetworkSidenav__line fr-p-0"></div>

                <fieldset class="fr-fieldset" id="label-hint"
                    aria-labelledby="text-label-hint-social-network-legend text-input-social-network-start-date text-input-social-network-end-date fr-btn-tertiary-social-network-reset">
                    <legend class="fr-fieldset__legend--bold fr-fieldset__legend" id="text-label-hint-legend">
                        Période personnalisée
                    </legend>

                    <div class="fr-fieldset__element ">
                        <div class="fr-input-group">
                            <span class="fr-hint-text fr-mb-2w">Format attendu : jj/mm/aaaa</span>

                            <label class="fr-label fr-text--bold" for="text-input-social-network-start-date">
                                Période du
                            </label>
                            <input v-model="startDatePicker" class="fr-input fr-input--error"
                                aria-describedby="text-input-social-network-start-date-messages"
                                id="text-input-social-network-start-date" type="date" @change="changeStartDate"
                                title="Afficher le sélecteur de dates" />
                            <p v-if="isNotRightStartDate" id="text-input-social-network-error-desc-error"
                                class="fr-error-text">
                                La date séléctionnée n'est pas disponible
                            </p>
                            <div class="fr-messages-group" id="text-input-social-network-start-date-messages"
                                aria-live="assertive">
                            </div>
                        </div>
                    </div>

                    <div class="fr-fieldset__element">
                        <div class="fr-input-group">
                            <label class="fr-label fr-text--bold fr-mt-2w" for="text-input-social-network-end-date">
                                au
                            </label>
                            <input v-model="endDatePicker" class="fr-input fr-input--error"
                                aria-describedby="text-input-social-network-date-messages"
                                id="text-input-social-network-end-date" name="end-date-input" type="date"
                                title="Afficher le sélecteur de dates" @change="changeEndDate" />
                            <p v-if="isNotRightEndDate" id="text-input-social-network-error-desc-error"
                                class="fr-error-text">
                                La date séléctionnée n'est pas valide
                            </p>
                            <p v-if="isNotRightPeriod" id="text-input-social-network-error-desc-error"
                                class="fr-error-text">
                                La période séléctionnée est invalide
                            </p>
                            <div class="fr-messages-group" id="text-input-social-network-end-date-messages"
                                aria-live="assertive">
                            </div>
                        </div>
                    </div>
                </fieldset>

                <button class="fr-btn fr-btn--tertiary fr-mt-1w fr-mb-4w fr-btn--icon-right fr-icon-close-circle-line"
                    @click="resetPresetDates" title="Réinitialiser" id="fr-btn-tertiary-social-network-reset">
                    Réinitialiser
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchBar from "../SearchDropdown.vue";
import { checkDateIsCorrect, checkPeriod, convertDate, getPeriod, findLabel } from "../../utils";
import {
    endPoint, getSocialNetworkQuery,
    getDaysSinceLastMonday, getDaysSinceFirstOfLastYear, getDaysSinceFirstOfLastMonth, fetchDataFilterByForSocialNetworkMinistries, fetchDataFilterByForSocialNetworkOrganisation, fetchDataFilterByForSocialNetwork
} from "../../services/api";

export default {
    name: "SocialNetworkSidenav",
    components: {
        SearchBar,
    },
    data() {
        return {
            isNotRightStartDate: false,
            isNotRightEndDate: false,
            isNotRightPeriod: false
        }
    },
    computed: {
        ...mapGetters([
            'getSocialTags',
            'getsocialDefaultTags',
            'getSocialNetworkParams',
            'getSocialNetworkMinistries',
            'getSocialNetworkOrganisations',
            'getSocialNetworkCompany',
            'getSocialNetworkSelectedPeriod',
            'getSocialNetworkPeriod',
            'getSocialNetworkStartDatePicker',
            'getSocialNetworkEndDatePicker',
            'getSelectedSocialNetworkMinistries',
            'getSelectedSocialNetworkOrganisations',
            'getSelectedSocialNetworkCompany',
        ]),
        selectedSocialNetworkMinistries: {
            get() {
                return this.getSelectedSocialNetworkMinistries;
            },
            set(value) {
                this.setSelectedSocialNetworkMinistries(value);
            }
        },
        selectedSocialNetworkOrganisations: {
            get() {
                return this.getSelectedSocialNetworkOrganisations;
            },
            set(value) {
                this.setSelectedSocialNetworkOrganisations(value);
            }
        },
        selectedSocialNetworkCompany: {
            get() {
                return this.getSelectedSocialNetworkCompany;
            },
            set(value) {
                this.setSelectedSocialNetworkCompany(value);
            }
        },
        ministries: {
            get() {
                return this.getSocialNetworkMinistries || []; // Default to an empty array
            }
        },
        organisations: {
            get() {
                return this.getSocialNetworkOrganisations
            }
        },
        socialNetworkCompanies: {
            get() {
                return this.getSocialNetworkCompany
            }
        },
        selectedPeriod: {
            get() {
                return this.getSocialNetworkSelectedPeriod || "default-period";
            },
            set(value) {
                this.setSocialNetworkSelectedPeriod(value);
            }
        },
        period: {
            get() {
                return this.getSocialNetworkPeriod;
            },
            set(value) {
                this.setSocialNetworkPeriod(value);
            }
        },
        startDatePicker: {
            get() {
                return this.getSocialNetworkStartDatePicker;
            },
            set(value) {
                this.setSocialNetworkStartDatePicker(value);
            }
        },
        endDatePicker: {
            get() {
                return this.getSocialNetworkEndDatePicker;
            },
            set(value) {
                this.setSocialNetworkEndDatePicker(value);
            }
        },
    },
    methods: {
        findLabelByValue(value, list) {
            // Convertir la liste réactive en une liste classique
            const plainList = JSON.parse(JSON.stringify(list));
            const item = plainList.find(el => String(el.value) === String(value));
            return item ? item.label : null;
        },
        ...mapActions([
            'setIsSocialNetworkSidenavVisible',
            'setIsDimensionsSegmentSelected',
            'setSocialNetworkSelectedPeriod',
            'setSocialNetworkStartDatePicker',
            'setSocialNetworkEndDatePicker',
            'setSocialNetworkPeriod',
            'setSelectedSocialNetworkMinistries',
            'resetSelectedSocialNetworkMinistries',
            'setSelectedSocialNetworkOrganisations',
            'resetSelectedSocialNetworkOrganisations',
            'setSelectedSocialNetworkCompany',
            'resetSelectedSocialNetworkCompany',
            'addSocialTag',
            'addSocialDefaultTag',
            'removeSocialDefaultTag',
            'removeSocialTag',
            'removeSocialTagsSites',
            'setIsDisableDim',
            'setDimensionName',
        ]),

        changeMinistries(event) {
            try {
                // Mise à jour de la sélection pour le ministère
                this.selectedSocialNetworkMinistries = event.target.value;
                // Indiquer à l'application que ce filtre est actif
                this.setDimensionName("Ministère de tutelle");
                this.setIsDimensionsSegmentSelected(true);
                this.setIsDisableDim(false);

                // Appels API pour actualiser les autres filtres en fonction du ministère sélectionné
                Promise.all([
                    fetchDataFilterByForSocialNetworkOrganisation(
                        endPoint,
                        this.selectedSocialNetworkMinistries,
                        this.selectedSocialNetworkCompany
                    ),
                    fetchDataFilterByForSocialNetwork(
                        endPoint,
                        this.selectedSocialNetworkMinistries,
                        this.selectedSocialNetworkOrganisations
                    )
                ])
                    .then(() => {
                        // Mise à jour des tags pour refléter le filtre appliqué
                        this.removeSocialDefaultTag({ label: "", role: "allMinistries" });
                        this.addSocialTag({
                            label: findLabel(this.ministries, this.selectedSocialNetworkMinistries),
                            role: "ministry"
                        });

                        // Actualisation des résultats de la requête principale
                        getSocialNetworkQuery(
                            this.selectedSocialNetworkMinistries,
                            this.selectedSocialNetworkOrganisations,
                            this.selectedSocialNetworkCompany,
                            this.period
                        );
                    })
                    .catch((error) => {
                        console.error("Error in changeMinistries flow:", error);
                    });
            } catch (error) {
                console.error("Error in changeMinistries method:", error);
            }
        },

        changeOrganisations(event) {
            try {
                // Mise à jour de la sélection pour l'organisation
                this.selectedSocialNetworkOrganisations = event.target.value;

                // Indiquer à l'application que ce filtre est actif
                this.setDimensionName("Entité / Organisation");
                this.setIsDimensionsSegmentSelected(true);
                this.setIsDisableDim(false);

                // Appels API pour actualiser les autres filtres en fonction de l'organisation sélectionnée
                Promise.all([
                    fetchDataFilterByForSocialNetworkMinistries(
                        endPoint,
                        this.selectedSocialNetworkOrganisations,
                        this.selectedSocialNetworkCompany
                    ),
                    fetchDataFilterByForSocialNetwork(
                        endPoint,
                        this.selectedSocialNetworkMinistries,
                        this.selectedSocialNetworkOrganisations
                    )
                ])
                    .then(() => {
                        // Mise à jour des tags pour refléter le filtre appliqué
                        this.removeSocialDefaultTag({ label: "", role: "allMinistries" });
                        this.addSocialTag({
                            label: findLabel(this.organisations, this.selectedSocialNetworkOrganisations),
                            role: "typeSite"
                        });

                        // Actualisation des résultats de la requête principale
                        getSocialNetworkQuery(
                            this.selectedSocialNetworkMinistries,
                            this.selectedSocialNetworkOrganisations,
                            this.selectedSocialNetworkCompany,
                            this.period
                        );
                    })
                    .catch((error) => {
                        console.error("Error in changeOrganisations flow:", error);
                    });
            } catch (error) {
                console.error("Error in changeOrganisations method:", error);
            }
        },

        changeSocialCompagny(event) {
            try {
                // Récupère la valeur et le label sélectionné
                const selectedValue = event.target.value;
                const selectedLabel = this.findLabelByValue(selectedValue, this.socialNetworkCompanies);

                // Met à jour la sélection du réseau social
                this.selectedSocialNetworkCompany = selectedValue;

                this.setDimensionName("Réseau Social");
                this.setIsDimensionsSegmentSelected(true);
                this.setIsDisableDim(false);

                // Met à jour les tags avec value et label
                this.removeSocialDefaultTag({ label: "", role: "allMinistries" });
                this.addSocialTag({ label: selectedLabel, role: "site" });

                // Effectue les appels API nécessaires
                Promise.all([
                    fetchDataFilterByForSocialNetworkMinistries(
                        endPoint,
                        this.selectedSocialNetworkOrganisations,
                        this.selectedSocialNetworkCompany
                    ),
                    fetchDataFilterByForSocialNetworkOrganisation(
                        endPoint,
                        this.selectedSocialNetworkMinistries,
                        this.selectedSocialNetworkCompany
                    )
                ])
                    .then(() => {
                        console.log("Filters updated successfully.");
                        getSocialNetworkQuery(
                            this.selectedSocialNetworkMinistries,
                            this.selectedSocialNetworkOrganisations,
                            this.selectedSocialNetworkCompany,
                            this.period
                        );
                    })
                    .catch(err => console.error("Error updating filters:", err));
            } catch (error) {
                console.error("Error in changeSocialCompagny method:", error);
            }
        },

        changeCurrentDate(event) {
            try {
                this.selectedPeriod = event.target.value || "default-period";
                this.setIsDimensionsSegmentSelected(false);
                this.setSocialNetworkStartDatePicker(undefined);
                this.setSocialNetworkEndDatePicker(undefined);

                if (this.selectedPeriod.includes("current-week")) {
                    this.removeSocialDefaultTag({ label: "", role: "currentDate" });
                    this.addSocialTag({ label: "Semaine courante", role: "currentDate" });
                    this.period = getDaysSinceLastMonday();
                } else if (this.selectedPeriod.includes("current-month")) {
                    this.removeSocialDefaultTag({ label: "", role: "currentDate" });
                    this.addSocialTag({ label: "Mois courant", role: "currentDate" });
                    this.period = getDaysSinceFirstOfLastMonth();
                } else if (this.selectedPeriod.includes("current-year")) {
                    this.removeSocialDefaultTag({ label: "", role: "currentDate" });
                    this.addSocialTag({ label: "Année courante", role: "currentDate" });
                    this.period = getDaysSinceFirstOfLastYear();
                }
                getSocialNetworkQuery(
                    this.selectedSocialNetworkMinistries,
                    this.selectedSocialNetworkOrganisations,
                    this.selectedSocialNetworkCompany,
                    this.period
                );
            } catch (error) {
                console.error("Erreur dans changeCurrentDate :", error);
            }
        },

        changeStartDate(event) {
            this.startDatePicker = event.target.value;
            this.isNotRightStartDate = checkDateIsCorrect(this.startDatePicker);
            this.isNotRightPeriod = checkPeriod(this.startDatePicker, this.endDatePicker);
            if (this.endDatePicker !== undefined && !this.isNotRightStartDate && !this.isNotRightPeriod) {
                this.period = getPeriod(this.startDatePicker, this.endDatePicker);
                this.setIsDimensionsSegmentSelected(false);
                this.setDimensionName("Début");
                this.setSocialNetworkSelectedPeriod("");
                this.setSocialNetworkPeriod(this.period);
                this.setSocialNetworkStartDatePicker(this.startDatePicker);
                this.setSocialNetworkEndDatePicker(this.endDatePicker);
                this.removeSocialDefaultTag({ label: "", role: "currentDate" });
                let label = convertDate(this.startDatePicker).replaceAll("-", "/") + " - "
                    + convertDate(this.endDatePicker).replaceAll("-", "/");
                this.addSocialTag({ label: label, role: "currentDate" });
                getSocialNetworkQuery(this.selectedSocialNetworkMinistries, this.selectedSocialNetworkOrganisations, this.selectedSocialNetworkCompany, this.period, this.startDatePicker, this.endDatePicker);
            }

        },
        changeEndDate(event) {
            this.endDatePicker = event.target.value;
            this.isNotRightEndDate = checkDateIsCorrect(this.endDatePicker);
            this.isNotRightPeriod = checkPeriod(this.startDatePicker, this.endDatePicker);
            if (this.startDatePicker !== undefined && !this.isNotRightEndDate && !this.isNotRightPeriod) {
                this.setIsDimensionsSegmentSelected(false);
                this.period = getPeriod(this.startDatePicker, this.endDatePicker);
                this.setSocialNetworkSelectedPeriod("");
                this.setSocialNetworkPeriod(this.period);
                this.setSocialNetworkStartDatePicker(this.startDatePicker);
                this.setSocialNetworkEndDatePicker(this.endDatePicker);
                this.removeSocialDefaultTag({ label: "", role: "currentDate" });
                let label = convertDate(this.startDatePicker).replaceAll("-", "/") + " - "
                    + convertDate(this.endDatePicker).replaceAll("-", "/");
                this.addSocialTag({ label: label, role: "currentDate" });
                getSocialNetworkQuery(this.selectedSocialNetworkMinistries, this.selectedSocialNetworkOrganisations, this.selectedSocialNetworkCompany, this.period, this.startDatePicker, this.endDatePicker);
            }
        },

        resetPresetDates() {
            this.setSocialNetworkStartDatePicker(undefined);
            this.setSocialNetworkEndDatePicker(undefined);
            this.setSocialNetworkSelectedPeriod("");
            this.setSocialNetworkPeriod(this.period);
            this.removeSocialTag({ label: "", role: "currentDate" });
            // Assurez-vous que getsocialDefaultTags existe et est un tableau
            if (this.getsocialDefaultTags && Array.isArray(this.getsocialDefaultTags)) {
                let filteredTags = this.getsocialDefaultTags.filter(tag => tag.label === "Mois glissant");
                if (filteredTags.length === 0) {
                    this.addSocialDefaultTag({ label: "Mois glissant", role: "currentDate" });
                }
            }
            this.isNotRightStartDate = false;
            this.isNotRightEndDate = false;
            getSocialNetworkQuery(this.selectedSocialNetworkMinistries, this.selectedSocialNetworkOrganisations, this.selectedSocialNetworkCompany, this.period);
        },

        async resetSites() {
            try {
                // Supprimer les tags existants liés aux sites
                this.removeSocialTagsSites();
                this.setIsDimensionsSegmentSelected(false);
                this.resetSelectedSocialNetworkMinistries();
                this.resetSelectedSocialNetworkOrganisations();
                this.resetSelectedSocialNetworkCompany();

                // Réinitialiser les données avec des appels API
                await Promise.all([
                    fetchDataFilterByForSocialNetworkMinistries(endPoint)
                        .catch(err => console.error("Error resetting ministries", err)),

                    fetchDataFilterByForSocialNetworkOrganisation(endPoint)
                        .catch(err => console.error("Error resetting organisations", err)),

                    fetchDataFilterByForSocialNetwork(endPoint)
                        .catch(err => console.error("Error resetting social networks", err))
                ]);

                // Ajouter un tag par défaut après la réinitialisation
                this.addSocialDefaultTag({ label: "Tous les ministères", role: "allMinistries" });

                // Recharger les données avec les nouvelles valeurs par défaut
                getSocialNetworkQuery(
                    this.selectedSocialNetworkMinistries,
                    this.selectedSocialNetworkOrganisations,
                    this.selectedSocialNetworkCompany,
                    this.period
                );

                console.log("Sites reset successfully.");
            } catch (error) {
                console.error("Error during resetSites execution:", error);
            }
        },

        closeSocialNetworkSidenav() {
            this.setIsSocialNetworkSidenavVisible(false);
        },
    },
    mounted() {
        fetchDataFilterByForSocialNetworkMinistries(endPoint)
            .catch(err => console.error("Error fetching ministrie"));
        fetchDataFilterByForSocialNetworkOrganisation(endPoint)
            .catch(err => console.error("Error fetching organisations"));
        fetchDataFilterByForSocialNetwork(endPoint)
            .catch(err => console.error("Error fetching social networks"));
    }
};
</script>

<style scoped lang="scss">
@import "../../styles/components/social-network/socialNetworkSideNav.scss";
</style>